<template>
  <div class="row m-0 align-items-center">
    <div class="col-auto p-0 relative" @mouseover="isHoverDonate = true" @mouseleave="isHoverDonate = false">
      <Button color="red" width="100" btnText="Donate Now" icon="arrow" size="big_menu" @buttonClicked="goTo('donate')">
        <IconArrowForward color="white" />
      </Button>
      <transition name="slideBottom">
        <div class="button_dropdown" v-if="isHoverDonate">
          <div class="row ms-0 text-center">
            <div class="col-12 my-3 pe-1 gold-text bold drop_heading font13">
              OTHER OPTIONS
            </div>
            <div class="col-12 mb-2 p-0">
              <Button color="green" width="100" btnText="Call Our Office" icon="arrow" size="" @buttonClicked="call('+441254698771')" class="font13" v-if="isUK">
                <IconPhone size="size16" />
              </Button>
              <Button color="green" width="100" btnText="Call Our Office" icon="arrow" size="" @buttonClicked="call('+27363521557')" class="font13" v-else>
                <IconPhone size="size16" />
              </Button>
            </div>
            <div class="col-12 p-0">
              <Button color="green" width="100" btnText="Ways to Donate" icon="arrow" size="" @buttonClicked="goTo('waystodonate')">
                <IconWays size="size16" />
              </Button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconPhone: defineAsyncComponent(() => import('@/components/icons/IconPhone.vue')),
    IconWays: defineAsyncComponent(() => import('@/components/icons/IconWays.vue'))
  },
  name: 'TopMenuDonateButton',
  data () {
    return {
      isHoverDonate: false
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    },
    call (val) {
      window.location.href = 'tel:' + val
    }
  }
}
</script>

<style scoped>

.button_dropdown {
  position: absolute;
  top: 2rem;
  z-index: 99;
  width: 100%;
  left: 0;
  padding-top: 1rem;
}
.drop_heading {
  letter-spacing: 4.4px;
}
.slideBottom-enter-active {
  transition: all 0.5s ease;
}
.slideBottom-leave-active {
  transition: all 0.5s ease;
}
.slideBottom-enter-from {
  transform: translateY(100%);
  opacity: 0;
}
.slideBottom-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

</style>
